@use "src/App";

.hero-container {
  padding: 3rem;
  display: flex;
  flex-direction: row;
  align-items: stretch;

  @media screen and (max-width: 1024px) {
    align-items: center;
    flex-direction: column;
    gap: 3rem;
  }
}

.hero-info-container {
  display: flex;
  flex-direction: column;
  gap: 6rem;
  max-width: 66rem;
}

.hero-header {
  font-size: 6.4rem;

  @media screen and (max-width: 1024px){
    font-size: 4.8rem;
  }
}

.hero-paragraph-container {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.hero-paragraph {
  font-size: 3.2rem;

  strong {
    color: App.$white;
  }

  @media screen and (max-width: 1024px){
    font-size: 2.4rem;
  }
}

.hero-canvas-container {
  width: 100%;

  @media screen and (max-width: 1024px){
    height: 32rem;
  }
}