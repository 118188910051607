@use "src/App";

$torus-size: 40rem;

.container-404 {
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-size: 6.4rem;

    @media screen and (max-width: 1024px){
      font-size: 4.8rem;
    }

    @media screen and (max-width: 680px){
      font-size: 3.2rem;
    }
  }
}

.error-container-404 {
  display: inline-flex;
  align-items: center;

  h1 {
    font-size: 24rem;

    @media screen and (max-width: 1024px){
      font-size: 16rem;
    }

    @media screen and (max-width: 680px){
      font-size: 8rem;
    }
  }
}

.torus-container-404 {
  width: $torus-size;
  height: $torus-size;

  @media screen and (max-width: 1024px){
    width: calc(#{$torus-size} * 0.8);
    height: calc(#{$torus-size} * 0.8);
  }

  @media screen and (max-width: 680px){
    width: calc(#{$torus-size} * 0.4);
    height: calc(#{$torus-size} * 0.4);
  }
}
