@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');

// --- COLORS ---

$space: #101010;
$stars: #606060;
$sub: #b4b4b4;
$white: #fffffe;

// --------------

html,
body {
  font-family: "Raleway", sans-serif;
  font-size: 10px;
  font-weight: 500;
}

body {
  background: $space;
  overflow-x: hidden;
}

h1,
h2,
h3 {
  color: $white;
  font-weight: 700;
}

p {
  color: $sub;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.canvas-container {
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: -1;
}

.main-container {
  max-width: 1280px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
}
